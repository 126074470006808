@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.c-topicspath {
  max-width: 1138px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  left: 0;
  bottom: 60px;
  z-index: 2; }
  @media screen and (max-width: 1168px) {
    .c-topicspath {
      padding: 0 15px; } }
  @media screen and (max-width: 1024px) {
    .c-topicspath {
      display: none; } }
  @media screen and (max-width: 568px) {
    .c-topicspath {
      bottom: 54px; } }
  .c-topicspath li {
    display: inline-block;
    font-size: 12px;
    color: #888888; }
    @media screen and (max-width: 768px) {
      .c-topicspath li {
        font-size: 0.7em; } }
    .c-topicspath li:after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: inline-block;
      margin-left: 8px; }
    .c-topicspath li:last-child:after {
      content: none; }
    .c-topicspath li > a {
      color: #888888;
      text-decoration: underline; }

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 231px;
  background-image: url("/inc/image/common/bg_lowerCaption.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
  background-color: #F7F7F7; }
  @media screen and (max-width: 768px) {
    .l-lowerCaption {
      height: 260px; } }
  @media screen and (max-width: 568px) {
    .l-lowerCaption {
      height: 190px; } }
  .l-lowerCaption__inner {
    max-width: 1138px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative; }
    @media screen and (max-width: 1168px) {
      .l-lowerCaption__inner {
        padding: 0 15px; } }
  .l-lowerCaption__bg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 180px;
    color: transparent;
    -webkit-text-stroke: 2px #fff;
    text-stroke: 2px #fff;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__bg {
        font-size: 100px; } }
    @media screen and (max-width: 568px) {
      .l-lowerCaption__bg {
        font-size: 80px; } }
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      .l-lowerCaption__bg {
        display: none; } }
  .l-lowerCaption__title {
    font-size: 38px;
    font-weight: 500;
    color: #333;
    padding: 0;
    width: auto;
    position: absolute;
    top: 43%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    line-height: 1.2; }
    @media screen and (max-width: 1168px) {
      .l-lowerCaption__title {
        padding: 0 15px; } }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__title {
        font-size: 32px; } }
    @media screen and (max-width: 568px) {
      .l-lowerCaption__title {
        font-size: 28px; } }
    .l-lowerCaption__title small {
      font-size: 18px;
      font-weight: 600;
      color: #C6040E;
      letter-spacing: 0.06em;
      text-transform: uppercase; }

.l-lowermainimg {
  height: 415px; }
  @media screen and (max-width: 768px) {
    .l-lowermainimg {
      height: 195px; } }
  @media screen and (max-width: 568px) {
    .l-lowermainimg {
      height: 95px; } }
  .l-lowermainimg__inner {
    background-size: 100%;
    height: 500px;
    width: 100%;
    position: absolute;
    top: 295px;
    left: -70px;
    border-radius: 10px; }
    @media screen and (max-width: 768px) {
      .l-lowermainimg__inner {
        height: 310px;
        top: 220px;
        left: -15px; } }
    @media screen and (max-width: 568px) {
      .l-lowermainimg__inner {
        height: 150px; } }

.c-lowermainVisual__scroll {
  display: flex;
  align-items: center;
  position: absolute;
  top: 530px;
  right: 0;
  transform: rotate(90deg) translateY(-48px); }
  @media screen and (max-width: 768px) {
    .c-lowermainVisual__scroll {
      display: none; } }
  .c-lowermainVisual__scroll-txt {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.05em;
    display: inline-block;
    margin-right: 12px; }
  .c-lowermainVisual__scroll-bar {
    display: inline-block;
    width: 100px;
    height: 2px;
    background-color: #CCCCCC;
    position: relative;
    overflow: hidden; }
    .c-lowermainVisual__scroll-bar:before {
      content: '';
      width: 50%;
      height: 2px;
      position: absolute;
      top: 0;
      left: -50%;
      display: inline-block;
      background-color: #C6040E; }
